import React from "react";
import LoginButton from "./LoginButton"; // Adjust the path if necessary


const styles = {
  container: {
    textAlign: "center",
    marginTop: "15%",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    height: '50vh',
  },
  headerText: {
    color: "#2A2A2A",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "10px",
  },
  subText: {
    color: "#4A4A4A",
    fontSize: "18px",
    marginBottom: "20px",
  },
};

// Updated LoginButton component for styling purposes
// Note: You might need to adjust this component according to your project setup

const LandingPage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.headerText}>
        Bienvenido al Sitio de Tableros del IMCO
      </div>
      <div style={styles.subText}>Da click en el botón para Ingresar</div>
      <LoginButton />
    </div>
  );
};

export default LandingPage;
