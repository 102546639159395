import React from 'react';
import { useNavigate } from 'react-router-dom';
import iconoComEstatal from '../assets/Icono Com Estatal-min.png';
import iconoComUrbana from '../assets/Icono Comp Urbana-min.png'; // Adjust this path if necessary

const UserMenu = () => {
  const navigate = useNavigate();

  // Styles
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    textAlign: 'center',
  };

  const buttonStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
    padding: '20px',
    backgroundColor: 'white',
    border: '2px solid #007bff',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
  };

  const imageStyle = {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
  };

  const textStyle = {
    marginTop: '10px',
    color: '#333',
    fontSize: '14px',
    fontWeight: 'bold',
  };

  const headingStyle = {
    color: '#333',
    margin: '20px 0',
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Seleccione un tablero</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button 
          style={buttonStyle} 
          onClick={() => window.location.href = '/ice'}
          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          <img src={iconoComEstatal} alt="ICE" style={imageStyle} />
          <span style={textStyle}>Índice de Competitividad Estatal</span>
        </button>
        <button 
          style={buttonStyle} 
          onClick={() => window.location.href = '/icu'}
          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          <img src={iconoComUrbana} alt="ICU" style={imageStyle} />
          <span style={textStyle}>Índice de Competitividad Urbano</span>
        </button>
      </div>
    </div>
  );
};

export default UserMenu;