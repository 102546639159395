import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';


const root = createRoot(document.getElementById('root')); // Use createRoot to create a root.
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-bv8048zxd6qasbxx.us.auth0.com"
      clientId="aaqKZ0ZTIv7WirBYsxEuHxxwK3tDI1bb"
      redirectUri={`${window.location.origin}/dashboard`}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
