import React from 'react';
import logo from '../assets/IMCO-logo-v01.png'; // Adjust the import path to your logo's location

const UserDashboard = () => {
  // Directly embedding the source or using a backend to fetch/proxy the content
  const iframeSrc = 'https://imco.metabaseapp.com/public/dashboard/e921c463-128d-4bb2-8acc-45471e607667'; // This should be a route that serves your iframe content
  const excelFileUrl = process.env.PUBLIC_URL + '/ICE 2023.xlsx';// Replace this with the actual URL to your Excel file

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <iframe 
        src={iframeSrc} 
        style={{ 
          width: '100%', 
          flexGrow: 1, // Makes the iframe grow to fill available space
          border: 'none' 
        }}
        title="User Dashboard"
      ></iframe>
      {/* Download Button */}
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <a href={excelFileUrl} download="ICE 2023.xlsx" style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', textDecoration: 'none', borderRadius: '5px' }}>
      Descarga de Datos
      </a>
      </div>
    </div>
  );
};

export default UserDashboard;
