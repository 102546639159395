// src/components/SupportContactPage.js

import React from 'react';

const SupportContactPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <p>Por favor contacta a soporte. tecnologia@imco.org.mx</p>
    </div>
  );
};

export default SupportContactPage;
