import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// Style for the button
const buttonStyle = {
  backgroundColor: '#0052cc',
  color: 'white',
  fontSize: '16px',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      style={buttonStyle}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#003D99')}
      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#0052cc')}
    >
      Ingresar
    </button>
  );
};

export default LoginButton;
