import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#333333] p-4 mt-8" style={{ fontFamily: 'Source Sans Pro, sans-serif' }}>
      <div className="container mx-auto text-center text-gray-300">
        <p>&copy;2024 | Instituto Mexicano para la Competitividad A.C. | Todos los derechos reservados.</p>
        <div className="flex justify-center space-x-4 mt-2">
          <a href="https://www.facebook.com/imcomx/" target="_blank" rel="noopener noreferrer" className="hover:text-white">Facebook</a>
          <a href="https://twitter.com/imcomx" target="_blank" rel="noopener noreferrer" className="hover:text-white">Twitter</a>
          <a href="https://www.instagram.com/imco_mx/" target="_blank" rel="noopener noreferrer" className="hover:text-white">Instagram</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
